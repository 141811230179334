import React from 'react';
import { CountdownCard, PlaylistBar, SectionHead } from '../Components';
import { Jumbotron } from './Jumbotron';

export function BannerSection(props) {
  const { activeId } = props;

  return (
    <section className="banner-section">
      <div className="top-line">
        <SectionHead { ...{ activeId } } />
        <CountdownCard />
      </div>
      <Jumbotron activeSection={activeId} />
      <PlaylistBar />
    </section>
  )
}
