import React from 'react';
import { CookiesProvider as ReactCookie } from 'react-cookie';
import { Route, useParams } from 'react-router-dom';
import { useToken } from '../Hooks';

export function CookiesProvider({ children }) {
  return (
    <ReactCookie>
      { children }
      <Route path="/:access_token">
        <TokenListener />
      </Route>
    </ReactCookie>
  )
}

function TokenListener() {
  let { access_token } = useParams()
  useToken({ cookieName: 'spotifyAuthToken', token: access_token })
  return null
}
