import React from 'react';
import Icon from '@hackclub/icons';

export function CloseButton(props) {
  const { pos, onClick } = props
  return (
    <button className={`close-view-button ${pos}`} onClick={onClick}>
      <Icon glyph="view-close-small" size={32}/>
    </button>
  )
}
