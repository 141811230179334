import React, { useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import './Styles/App.scss';

import { CookiesProvider } from './Providers';
import { NavBar, BannerSection } from './Containers';
import { HomeButton } from './Components';

function App() {
  const [ activeSection, setActiveSection ] = useState("jumboMap");
  const [ activeSocials, setActiveSocials ] = useState([]);
  const topNavState = { active: activeSection, setActive: setActiveSection };
  const socials = { active: activeSocials, setActive: setActiveSocials };

  return (
    <Router>
      <CookiesProvider>
        <main className="App">
          <HomeButton { ...topNavState } />
          <NavBar id={ "topNav" } navState={ topNavState } />
          <NavBar id={ "socials" } navState={ socials }/>
          <BannerSection activeId={ activeSection } />
        </main>
      </CookiesProvider>
    </Router>
  );
}

export default App;
