import React, { useCallback, useEffect, useRef, useState } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import { CloseButton, MapInfoCard, MapMarker } from '../Components';

//import { MapViewDetails } from '../Components';

const MB_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const MB_STYLE = process.env.REACT_APP_MB_STYLE;
const MAP_CONFIG = {
  latitude: 37.632,
  longitude: -97.860,
  zoom: 3.190,
  bearing: 12.031,
  pitch: 30.364
} // <- Attr keys match react-map-gl default export MapGL init props

export function JumboMap() {
  const mapRef = useRef()
  const routePath = useRef()
  const wrapperRef = useRef()
  const [cardPos, setCardPos] = useState(null);
  const [infoCardShows, setInfoCardShows] = useState(false);
  const [markerCoords, setMarkerCoords] = useState(null);
  const [currentLoc, setCurrentLoc] = useState(null);
  const [viewport, setViewport ] = useState(MAP_CONFIG);

  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  const setMapViewportSize = useCallback(
    () => {
      try {
        const { w, h } = domElementDimensions()
        setWidth(w)
        setHeight(h)
      }
      catch {
        window.removeEventListener('resize', setMapViewportSize)
      }
    },
    []
  );

  useEffect(() => {
    if (!markerCoords) return
    projectMarkerCoords()
  })

  useEffect(() => {
    if (!!width && !!height) return
    window.addEventListener('resize', setMapViewportSize)
    setMapViewportSize()
  }, [setMapViewportSize, width, height])

  return (
    <div className="mb-wrapper" ref={ wrapperRef }>
      { !!width && !!height &&
        <MapGL
          className="mb-canvas"
          ref={ mapRef }
          { ...viewport }
          width={ width }
          height={ height }
          mapboxApiAccessToken={ MB_TOKEN }
          mapStyle={ MB_STYLE }
          onLoad={ onLoad }
          onViewportChange={ nextViewport => setViewport(nextViewport) }>
          { markerCoords && (
            <Marker { ...markerCoords } offsetTop={ -56 } offsetLeft={ -24 }>
              <MapMarker onClick={ cardToggler }/>
            </Marker>
          ) }
        </MapGL>
      }
      { !!infoCardShows && <MapInfoCard { ...{ currentLoc, cardPos } }/> }
      { !!infoCardShows && <CloseButton pos={cardPos} onClick={cardToggler}/> }
    </div>
  )

  function domElementDimensions() {
    const elem = wrapperRef.current;
    if (elem) {
      return { w: elem.offsetWidth, h: elem.offsetHeight }
    }
  }

  function onLoad() {
    getRouteData()
      .then(() => setMarkerCoords( featureCoords() ))
      .catch((e) => console.error(e))
  }

  function featureCoords(feature='') {
    const ft = !!feature ? feature : routePath.current[800]
    setCurrentLoc(ft)
    return {
      latitude: ft.geometry.coordinates[0][1],
      longitude: ft.geometry.coordinates[0][0]
    }
  }

  function getRouteData() {
    return new Promise((resolve, reject) => {
      try {
        const mb = mapRef.current.getMap();
        const rt = mb.queryRenderedFeatures({ layers: ['amtrhackrouteraw'] });
        routePath.current = rt;
        resolve()
      }
      catch (e) {
        reject(e)
      }
    })
  }

  function projectMarkerCoords() {
    const mb = mapRef.current.getMap()
    const { x } = mb.project([markerCoords.longitude, markerCoords.latitude])
    const centerWidth = wrapperRef.current.offsetWidth/2
    setCardPos( x < centerWidth ? 'right-side' : 'left-side' )
  }

  function cardToggler() {
    setInfoCardShows(prevState => !prevState)
  }
}
