import React from 'react';
import Icon from '@hackclub/icons';

export function NavButton( props ) {
  const { id, label, isGlyph, setActive, isActive } = props
  const className = !!isActive ? "nav-bar-button active" : "nav-bar-button";

  return (
    <button className={className} onClick={clickHandler}>
      { !isGlyph ? label :
        <Icon glyph={label} size={32} />
      }
    </button>
  )

  function clickHandler(e) {
    e.stopPropagation()
    setActive(id)
  }
}
