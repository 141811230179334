import React, { useRef, useState } from 'react';
import Icon from '@hackclub/icons';
import { NavButton } from '../Components';
import { useDetectClickOutside } from '../Hooks';

const MENUS = {
  topNav: {
    glyph: { name: "menu", size: 64 },
    buttons: [
      { id: "about", label: "About" },
      { id: "theGame", label: "The Game" },
      { id: "teams", label: "Teams" },
      { id: "playlists", label: "Playlists" }
    ]
  },
  socials: {
    glyph: { name: "friend", size: 48 },
    buttons: [
      { id: "fb", label: "facebook", isGlyph: true },
      { id: "gh", label: "github", isGlyph: true },
      { id: "ig", label: "instagram", isGlyph: true },
      { id: "twitch", label: "twitch", isGlyph: true },
      { id: "slack", label: "slack", isGlyph: true }
    ]
  }
}

export function NavBar( props ) {
  const { id, navState: { active, setActive } } = props;
  const [ toggleOpen, setToggleOpen ] = useState(false);
  const wrapperRef = useRef(null)

  useDetectClickOutside(wrapperRef, clickOutside)

  return (
    <>
      <nav
        className={ toggleOpen ? 'nav-bar expand' : 'nav-bar collapse' }
        ref={ wrapperRef }>
        <button className='nav-bar-toggler' onClick={ clickHandler }>
          <Icon glyph={MENUS[id].glyph.name} size={MENUS[id].glyph.size}/>
        </button>
        {MENUS[id].buttons.length &&
          <span className = 'nav-overflow-wrapper'>
            <span className = 'nav-buttons'>
              {MENUS[id].buttons.map((btn) =>
                <NavButton
                  key={`nav_${btn.id}`}
                  isActive={ btn.id === active }
                  { ...{ ...btn, setActive } }/>
              )}
            </span>
          </span>
        }
      </nav>
    </>
  )

  function clickHandler() {
    setToggleOpen(pastState => !pastState)
  }

  function clickOutside() {
    if (!!toggleOpen) {
      setToggleOpen(pastState => !pastState)
    } else {
      return
    }
  }
}
