import React from 'react'

export function SectionHead(props) {
  const { activeId } = props;

  return (
    <h1 className="section-head">
      {
        activeId === "about" &&
        <>AMTR<em>hA</em><em>cK</em><em>club</em></>
      }
      {
        activeId === "jumboMap" &&
        <>AMTR<em>hA</em><em>cK</em><em>club</em></>
      }
      {
        activeId === "theGame" &&
        <>The Game</>
      }
    </h1>
  )
}
