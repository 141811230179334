import React, { useEffect, useState, useRef } from 'react';
import { ContentSection } from '../ContentSections';

const DEFAULT = 'jumboMap'
const IN_VIEW = 'translateY(0)'
const OUT_BTM = 'translateY(105%)'
const OUT_TOP = 'translateY(-105%)'

export function Jumbotron(props) {
  const { activeSection } = props
  const [ contentSection, setContentSection ] = useState()
  const defaultContent = useRef()
  const slotZero = useRef()

  useEffect(() => {
    if (activeSection !== DEFAULT) {
      setContentSection(activeSection)
    }
  }, [activeSection])

  useEffect(() => {
    if (!defaultContent.current) return
    if (activeSection === DEFAULT) {
      defaultContent.current.style.transform = IN_VIEW
    }

    if (slotZero.current && activeSection === DEFAULT) {
      defaultContent.current.style.transform = IN_VIEW
      slotZero.current.style.transform = OUT_TOP
    }

    if (slotZero.current && activeSection !== DEFAULT) {
      defaultContent.current.style.transform = OUT_BTM
      slotZero.current.style.transform = IN_VIEW
    }

  }, [activeSection, contentSection])

  return (
    <div className="jumbotron">
      <ContentSection
        sectionId={DEFAULT}
        ref={defaultContent}/>
      { contentSection &&
        <ContentSection sectionId={contentSection} ref={slotZero}/>}
    </div>
  )
}
