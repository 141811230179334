import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

export function useToken(props) {
  const { cookieName, token } = props
  const [, setCookie] = useCookies([cookieName])

  useEffect(() => {
    if (token) {
      setCookie(cookieName, token, { path: '/' });
    }
  })

  return null
}
