import React from 'react'
import { AboutLanding } from './AboutLanding';
import { GameLanding } from './GameLanding';
import { JumboMap } from './JumboMap';
import { PlaylistsLanding } from './PlaylistsLanding';
import { TeamsLanding } from './TeamsLanding';

const ContentSections  = {
  about: <AboutLanding />,
  jumboMap: <JumboMap />,
  theGame: <GameLanding />,
  playlists: <PlaylistsLanding />,
  teams: <TeamsLanding />
}

export const ContentSection = React.forwardRef((props, ref) => {
  const { sectionId } = props;

  return (
    <div id={sectionId} className="jumbo-content" ref={ref}>
      { ContentSections[sectionId] }
    </div>
  )
})
