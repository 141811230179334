import React, { useState, useEffect } from 'react';

const DEPARTURE = new Date("2021-07-04T22:00:00.000-07:00").getTime()

export function CountdownCard() {
  const [ days, setDays ] = useState();
  const [ hours, setHours ] = useState();
  const [ minutes, setMinutes ] = useState();
  const [ seconds, setSeconds ] = useState();

  useEffect(() => { countdown() }, [])

  useEffect(() => {
    setTimeout(() => {
      countdown()
    }, 1000)
  })

  return (
    <div className="countdown-card">
      <span className="countdown-head" data-label="From: ">
        Los Angeles, CA
      </span>
      <span className="countdown-head" data-label="To: ">
        Burlington, VT
      </span>
      <span className="countdown-head" data-label="Depart: ">
        July 04, 2021
      </span>
      <div className="countdown-timer">
        <span className="timer-display" data-label="days">{days}</span>
        <span className="timer-display" data-label="hours">{hours}</span>
        <span className="timer-display" data-label="minutes">{minutes}</span>
        <span className="timer-display" data-label="seconds">{seconds}</span>
      </div>
    </div>
  )

  function countdown() {
    const padStr = n => n < 10 ? `0${n}` : `${n}`
    const currentDate = new Date().getTime();
    let secondsLeft = (DEPARTURE - currentDate)/1000;

    setDays( padStr( parseInt(secondsLeft/86400) ));
    secondsLeft = secondsLeft % 86400;
    setHours( padStr( parseInt(secondsLeft/3600) ));
    secondsLeft = secondsLeft % 3600;
    setMinutes( padStr( parseInt(secondsLeft/60) ));
    setSeconds( padStr( parseInt(secondsLeft%60) ));

  }
}
