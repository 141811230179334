import React from 'react'

export function MapInfoCard(props) {
  const { currentLoc, cardPos } = props
  const { properties: {
    name: currentSection, "tiger:county": county
  } } = currentLoc;

  return (
    <div className={`map-info-card ${cardPos}`}>
      <div className="map-info-card-subsection">
        <h3 className="map-info-card-head">Current Location</h3>
        <div className="card-subsection-row">
          <span className="location-info" data-label="Rail Section: ">
            { currentSection }
          </span>
          <span className="location-info" data-label="County: ">
            { county }
          </span>
        </div>
      </div>
      <div className="map-info-card-subsection">
        <h3 className="map-info-card-head">More Data</h3>
      </div>
      <div className="map-info-card-subsection">
        <h3 className="map-info-card-head">More Data</h3>
        <div className="card-subsection-row">
          <p className="location-info note" data-label="Idea: ">
            Hackathon series around OpenStreetMap & Overpass QL, visualize stories along route
          </p>
          <p className="location-info note" data-label="ToDo: ">
            Workshop & Examples for Overpass QL & Mapbox; Express on-ramp to ProjectLand
          </p>
        </div>
      </div>
    </div>
  )
}
