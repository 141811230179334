import React from 'react';
import Icon from '@hackclub/icons';

export function MapMarker(props) {
  const { onClick } = props;

  return (
    <span
      className="map-marker"
      onClick={ onClick }>
      <Icon className="map-marker-icon" glyph="clubs" size={36}/>
    </span>
  )
}
