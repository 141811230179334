import React from 'react';
import { SpotifyApiContext /*, Playlist*/ } from 'react-spotify-api';
import { SpotifyAuth } from 'react-spotify-auth';
import 'react-spotify-auth/dist/index.css';
import { useCookies } from 'react-cookie';


//0Y7Lrzn2rXk0W6vZVMe8oW <-- playlist id
const PLAYLIST_ID = "0Y7Lrzn2rXk0W6vZVMe8oW"
const REDIRECT_URI = process.env.NODE_ENV === "production"
  ? process.env.REACT_APP_SPOTIFY_REDIRECT
  : "http://localhost:3000/redirect"

export function PlaylistBar() {
  const [ cookies ] = useCookies(['spotifyAuthToken'])
  const token = cookies.spotifyAuthToken;

  return (
    <div className="playlist-bar">
    { // iframe embed === placeholder; playback-api === $$s
      token ? (
      <SpotifyApiContext.Provider value={token}>
        <iframe
          className="spotify-player"
          title="spotify-embed-placeholder"
          src={`https://open.spotify.com/embed/playlist/${PLAYLIST_ID}`}
          frameBorder="0" allowtransparency="true" allow="encrypted-media">
        </iframe>
      </SpotifyApiContext.Provider>
    ) : (
      // Display the login page
      <SpotifyAuth
        btnClassName='playlist-bar-auth'
        redirectUri={ REDIRECT_URI }
        clientID={ process.env.REACT_APP_SPOTIFY_CLIENT_ID }
        scopes={[
          'user-read-private',
          'user-read-email',
          'streaming',
          'user-library-read',
          'user-library-modify',
          'user-read-playback-state',
          'user-modify-playback-state'
        ]}
      />
    )}
    </div>
  )
}

// Playlist Comp: demos react-spotify-auth & react-spotify-api get token ok
// Playback interface requires react-spotify-web-playback
/*<Playlist id={ PLAYLIST_ID }>
   {({ data, loading, error }) => {
     console.dir(data)
     return data ? (
       <h2>{`Playlist: ${data.name}`}</h2>
     ) : null } }
 </Playlist>*/
