import React from 'react'
import Icon from '@hackclub/icons';

export function HomeButton(props) {
  const { active, setActive } = props
  const className = active === 'jumboMap' ? 'home-button' : 'home-button shows'

  return (
    <button className={className} onClick={clickHandler}>
      <Icon glyph="compass" size={48}/>
    </button>
  )

  function clickHandler() {
    setActive('jumboMap')
  }

}
